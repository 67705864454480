import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ScotSwainson = () => {
  return (
    <Layout>
      <Seo title="Doctor Scot Swainson, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet
                <br />
                Dr. Scot
                <br />
                Swainson
              </h1>
            </div>
          </div>
        </div>
      </div>

      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/dr-scot-swainson.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Swainson was born and raised in Cheyenne, Wyoming and
                completed pre-veterinary studies at the University of Wyoming.
                After receiving his DVM degree from Colorado State University in
                1994, Dr. Swainson completed a one-year general internship in
                Los Angeles, California followed by a one-year surgical
                internship in Winter Park, Florida. Dr. Swainson then completed
                a 3-year residency in small animal surgery at Iowa State
                University. Following his residency Dr. Swainson was a clinical
                instructor at the University of Wisconsin.
              </p>
              <p>
                Dr. Swainson became board certified as a Diplomate of the
                American College of Veterinary Surgeons in 2000.
              </p>
              <p>
                Dr. Swainson then entered private practice and has worked in
                Boston, MA, Loveland CO, and Albuquerque, NM. He joined Colorado
                Canine Orthopedics in the summer of 2008 and has settled himself
                and family here in Colorado Springs, CO.
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/445632273"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default ScotSwainson
